import React from 'react'
import Greekrise from '../components/Greekrise'

const Popup = () => {
  return (
    <div>
        <Greekrise/>
    </div>
  )
}

export default Popup